import React from "react"
import { Colors } from "../constants"
import { ContainerContent, ContainerContentFixed } from "../containers"
import { TitleSection } from "../titles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ButtonSecondary } from "../buttons"
import { InternalLink, InternalLinkTypes } from "../links"
import { Row, List, Col } from "antd"
import {
  faStar,
  faStoreAlt,
  faHouseDamage,
  faHouseUser,
  faMoneyBill
} from "@fortawesome/free-solid-svg-icons"

const whyUs = [
  {
    icon: faStoreAlt,
    title: "Quality suppliers and materials"
  },
  {
    icon: faStar,
    title: "Quality work, assured"
  },
  {
    icon: faHouseDamage,
    title: "Comprehensive business insurance"
  },
  {
    icon: faMoneyBill,
    title: "Best prices with best service"
  },
  {
    icon: faHouseUser,
    title: "Qualified and experienced contractors"
  }
]
export const ContentAboutUs = () => (
  <ContainerContent
    style={{
      background: Colors.Blue,
      color: Colors.White,
      textAlign: "left"
    }}
  >
    <ContainerContentFixed style={{ maxWidth: "900px" }}>
      <Row gutter={[64, 32]}>
        <Col md={12} xs={24}>
          <TitleSection style={{ color: Colors.White }}>
            Why choose UCC VIC?
          </TitleSection>
          {
            <List
              itemLayout="horizontal"
              dataSource={whyUs}
              style={{ color: "white" }}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <FontAwesomeIcon
                        icon={item.icon}
                        style={{
                          fontSize: "24px",
                          marginTop: "4px",
                          color: Colors.White
                        }}
                      />
                    }
                    title={
                      <b style={{ color: Colors.White, fontSize: "1.1rem" }}>
                        {item.title}
                      </b>
                    }
                    description={
                      <p style={{ color: Colors.White, fontSize: "1rem" }}>
                        {item.description}
                      </p>
                    }
                  />
                </List.Item>
              )}
            />
          }
        </Col>

        <Col md={12} xs={24}>
          <TitleSection style={{ color: Colors.White }}>
            About UCC VIC team
          </TitleSection>
          <div style={{ fontSize: "1rem" }}>
            <p>
              Urban Concrete Contractors (UCC VIC) is a Victoria based concrete
              and landscaping company.
            </p>
            <p>
              That offers professional, reliable and efficient services in the
              North and West of Melbourne.
            </p>
            <p>
              Our experienced team of contractors will take care of all of your
              urban concrete needs, contact us today for your quote.
            </p>
          </div>
          <br />
          <ButtonSecondary>
            <InternalLink url={InternalLinkTypes.Quote}>Get Quote</InternalLink>
          </ButtonSecondary>
        </Col>
      </Row>
    </ContainerContentFixed>
  </ContainerContent>
)
