export const ExternalLinkTypes = {
  GeneralPhoneLink: "tel:+61448575781",
  GeneralPhoneText: "+61 448 575 781",
  GeneralEmailLink: "mailto:info@uccvic.com.au",
  GeneralEmailText: "info@uccvic.com.au",
  FacebookLink: "https://www.facebook.com/uccvic",
  FacebookText: "@uccvic",
  InstagramLink: "https://www.instagram.com/urbanconcretecontractors/",
  InstagramText: "@urbanconcretecontractors"
}
