import React, { useState, useCallback } from "react"
import { object } from "prop-types"
import { Form, Input, Spin, Alert, Button, Radio } from "antd"
import axios from "axios"
import { Colors } from "../constants"

const FORM_STATUS = {
  OPEN: "OPEN",
  SUBMITTING: "SUBMITTING",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
  SUBMIT_ERROR: "SUBMIT_ERROR"
}
const API = axios.create({
  baseURL: "https://api.uccvic.com.au"
})

export const QuoteForm = ({ services }) => {
  const [formStatus, setFormStatus] = useState(FORM_STATUS.OPEN)
  // const [service, setService] = useState()
  // const onChangeService = useCallback(
  //   event => {
  //     setService(event.target.value)
  //   },
  //   [setService]
  // )

  const onFinish = useCallback(values => {
    let msg = ""
    Object.keys(values).forEach(v => {
      msg += `${v.toUpperCase()}: ${values[v]}\n`
    })

    setFormStatus(FORM_STATUS.SUBMITTING)
    API.post("/sendemail", {
      subject: "Web Quote Request",
      message: msg //contains all details including name, email phone
    })
      .then(response => {
        if (response) {
          setFormStatus(FORM_STATUS.SUBMIT_SUCCESS)
        }
      })
      .catch(err => {
        setFormStatus(FORM_STATUS.SUBMIT_ERROR)
        console.error("Error submitting form", err)
      })
  }, [])

  const disableEdit =
    formStatus === FORM_STATUS.SUBMITTING ||
    formStatus === FORM_STATUS.SUBMIT_SUCCESS
  const formItemLayout = {
    labelCol: { md: { span: 8 }, sm: { span: 24 } },
    wrapperCol: { md: { span: 16 }, sm: { span: 24 } }
  }
  const buttonItemLayout = {
    wrapperCol: { md: { span: 16, offset: 8 }, sm: { span: 24 } }
  }
  return (
    <div
      style={{
        backgroundColor: Colors.LightGray,
        padding: "32px 32px"
      }}
    >
      <h2 style={{ fontWeight: "bold", fontSize: "1rem" }}>
        Send Quote Request
      </h2>
      <Form {...formItemLayout} onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: "Phone is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Service"
          name="service"
          rules={[{ required: true, message: "Service is required" }]}
        >
          <Radio.Group disabled={disableEdit}>
            {services.map(item => (
              <Radio key={item.id} value={item.id} style={{ display: "block" }}>
                {item.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: "Address is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Area (sqm)"
          name="area"
          rules={[{ required: false, message: "Area is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Notes"
          name="notes"
          rules={[{ required: false, message: "Notes is required" }]}
        >
          <Input.TextArea rows={4} disabled={disableEdit} />
        </Form.Item>

        <Form.Item {...buttonItemLayout}>
          <Button
            type="primary"
            htmlType="submit"
            // shape={"round"}
            disabled={disableEdit}
            size={"large"}
            style={{ marginBottom: "8px" }}
          >
            Send Quote Request
          </Button>
          {formStatus === FORM_STATUS.SUBMITTING && (
            <Spin size="large">
              <Alert
                showIcon
                message="Sending your quote request..."
                type="info"
                description="Please wait while we send your quote request. This may take a few seconds."
              />
            </Spin>
          )}
          {formStatus === FORM_STATUS.SUBMIT_SUCCESS && (
            <Alert
              type="success"
              showIcon
              message="Your quote request has been sent. We will be in touch soon."
              description=""
            />
          )}
          {formStatus === FORM_STATUS.SUBMIT_ERROR && (
            <Alert
              type="error"
              showIcon
              message="Something went wrong"
              description="There was an error in sending your quote request. Try sending again. If error persists, contact directly via email."
            />
          )}
        </Form.Item>
      </Form>{" "}
    </div>
  )
}

QuoteForm.propTypes = {
  style: object
}
