import React from "react"
import { object } from "prop-types"
import logo from "./ucc_trans_landscape.png"

export const LogoHeader = ({ style }) => {
  return (
    <img
      alt={"logo_header"}
      src={logo}
      style={{
        maxWidth: "300px",

        ...style
      }}
    />
  )
}

LogoHeader.propTypes = {
  style: object
}
