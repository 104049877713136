import React from "react"
import { object } from "prop-types"

import { ContactListItem } from "./contact_list_item"
import { ExternalLink, ExternalLinkTypes } from "../links"
import { SocialMedia } from "../social_media"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
export const ContactInfo = ({ style }) => (
  <div>
    <div>
      <ContactListItem
        icon={faEnvelope}
        title={"Email"}
        content={
          <ExternalLink url={ExternalLinkTypes.GeneralEmailLink}>
            {ExternalLinkTypes.GeneralEmailText}
          </ExternalLink>
        }
      />
      <ContactListItem
        icon={faPhone}
        title={"Phone"}
        content={
          <ExternalLink url={ExternalLinkTypes.GeneralPhoneLink}>
            {ExternalLinkTypes.GeneralPhoneText}
          </ExternalLink>
        }
      />
      <SocialMedia />
      {/*<ContactListItem title={"Follow us"} content={<SocialMedia />} />*/}
    </div>
  </div>
)

ContactInfo.propTypes = {
  style: object
}
