import React from "react"
import { object, string, any, node } from "prop-types"
import { Row, Col } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Colors } from "../constants"

export const ContactListItem = ({ style, icon, title, content }) => (
  <div style={{ padding: "8px 0", ...style }}>
    <Row gutter={16}>
      <Col xs={6} md={3}>
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            style={{
              fontSize: "24px",
              marginTop: "4px",
              color: Colors.Blue
            }}
          />
        )}
      </Col>
      <Col xs={18} md={21}>
        <div>
          <b>{title}</b>
        </div>
        <div>{content}</div>
      </Col>
    </Row>
  </div>
)

ContactListItem.propTypes = {
  style: object,
  icon: node,
  title: string.isRequired,
  content: any.isRequired
}
