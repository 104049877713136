/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { Colors } from "./constants"
import { Footer } from "./footer"
import { Layout as LayoutAntd, Row, Col } from "antd"
import { Nav } from "./nav"

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <LayoutAntd>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <LayoutAntd>
        <LayoutAntd.Content>
          <div
            style={{
              margin: `0 auto`,
              background: Colors.Blue
              // maxWidth: 960,
              // padding: `0 1.0875rem 1.45rem`
            }}
          >
            <main>{children}</main>{" "}
          </div>
          <Footer />
        </LayoutAntd.Content>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <LayoutAntd.Sider
              className={"app-mobile-menu"}
              reverseArrow={true}
              style={{
                zIndex: 11
              }}
              theme="dark"
              defaultCollapsed={true}
              collapsedWidth={0}
              // breakpoint={"md"}
              collapsible={true}
              //width={"100%"}
              zeroWidthTriggerStyle={{
                backgroundColor: Colors.Blue,
                color: Colors.White,
                top: "16px"
              }}
            >
              <Nav mode={"inline"} />
            </LayoutAntd.Sider>
          </Col>
        </Row>
      </LayoutAntd>
    </LayoutAntd>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
