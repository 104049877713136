import React, { useState, useCallback } from "react"
import { object } from "prop-types"
import { Form, Input, Spin, Alert, Button } from "antd"
import axios from "axios"
import { Colors } from "../constants"

const FORM_STATUS = {
  OPEN: "OPEN",
  SUBMITTING: "SUBMITTING",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
  SUBMIT_ERROR: "SUBMIT_ERROR"
}
const API = axios.create({
  baseURL: "https://api.uccvic.com.au"
})

const ContactForm = () => {
  const [formStatus, setFormStatus] = useState(FORM_STATUS.OPEN)

  const onFinish = useCallback(values => {
    let msg = ""
    Object.keys(values).forEach(v => {
      msg += `${v.toUpperCase()}: ${values[v]}\n`
    })

    setFormStatus(FORM_STATUS.SUBMITTING)
    API.post("/sendemail", {
      subject: "Web Contact",
      message: msg //contains all details including name, email phone
    })
      .then(response => {
        if (response) {
          setFormStatus(FORM_STATUS.SUBMIT_SUCCESS)
        }
      })
      .catch(err => {
        setFormStatus(FORM_STATUS.SUBMIT_ERROR)
        console.error("Error submitting form", err)
      })
  }, [])

  const disableEdit =
    formStatus === FORM_STATUS.SUBMITTING ||
    formStatus === FORM_STATUS.SUBMIT_SUCCESS
  const formItemLayout = {
    labelCol: { md: { span: 8 }, sm: { span: 24 } },
    wrapperCol: { md: { span: 16 }, sm: { span: 24 } }
  }
  const buttonItemLayout = {
    wrapperCol: { md: { span: 16, offset: 8 }, sm: { span: 24 } }
  }
  return (
    <div
      style={{
        backgroundColor: Colors.LightGray,
        padding: "32px 32px"
      }}
    >
      <h2 style={{ fontWeight: "bold", fontSize: "1rem" }}>Send an Enquiry</h2>
      <Form {...formItemLayout} onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: "Phone is required" }]}
        >
          <Input disabled={disableEdit} />
        </Form.Item>
        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: "Message is required" }]}
        >
          <Input.TextArea rows={4} disabled={disableEdit} />
        </Form.Item>

        <Form.Item {...buttonItemLayout}>
          <Button
            type="primary"
            htmlType="submit"
            // shape={"round"}
            disabled={disableEdit}
            size={"large"}
            style={{ marginBottom: "8px" }}
          >
            Send message
          </Button>
          {formStatus === FORM_STATUS.SUBMITTING && (
            <Spin size="large">
              <Alert
                showIcon
                message="Sending your message..."
                type="info"
                description="Please wait while we send your message. This may take a few seconds."
              />
            </Spin>
          )}
          {formStatus === FORM_STATUS.SUBMIT_SUCCESS && (
            <Alert
              type="success"
              showIcon
              message="Your message has been sent. We will be in touch soon."
              description=""
            />
          )}
          {formStatus === FORM_STATUS.SUBMIT_ERROR && (
            <Alert
              type="error"
              showIcon
              message="Something went wrong"
              description="There was an error in sending your message. Try sending again. If error persists, contact directly via email."
            />
          )}
        </Form.Item>
      </Form>{" "}
    </div>
  )
}

ContactForm.propTypes = {
  style: object
}

export default ContactForm
