import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { LogoHeader } from "./logos"
import { Colors } from "./constants"
import { Nav } from "./nav"
import { Row, Col } from "antd"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: Colors.Blue
      // marginBottom: `1.45rem`
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        // maxWidth: 960,
        padding: `1rem`
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            textDecoration: `none`
          }}
        >
          <LogoHeader />
        </Link>
      </h1>{" "}
      <Row>
        <Col xs={0} sm={0} md={24}>
          <div
            style={{ float: "right", textAlign: "right", marginTop: "-48px" }}
          >
            <Nav
              style={{
                background: Colors.Blue,
                textAlign: "right",
                marginTop: "8px"
              }}
              mode={"horizontal"}
            />
            {/*<ButtonSecondary style={{ margin: "4px" }}>*/}
            {/*  <InternalLink url={InternalLinkTypes.Quote}>*/}
            {/*    Get Quote*/}
            {/*  </InternalLink>*/}
            {/*</ButtonSecondary>*/}
          </div>
        </Col>
      </Row>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
