import React from "react"
import { Row, Col } from "antd"
import { Colors } from "../constants/index"
import { ContainerContentFixed } from "../containers/index"
import { LogoFooter } from "../logos/index"

import "./footer.css"
import { ExternalLink, InternalLink, InternalLinkTypes } from "../links"
import { ContactInfo } from "../contact"

const FooterLinksContainer = ({ children }) => (
  <div style={{ padding: "2rem" }}>{children}</div>
)

const Footer = () => {
  return (
    <footer
      className={"cp-footer"}
      style={{
        backgroundColor: Colors.LightGray,
        fontSize: "0.9rem",
        lineHeight: "1.2rem",
        borderTop: `2px solid ${Colors.Blue}`
      }}
    >
      <ContainerContentFixed style={{ padding: 0 }}>
        <Row gutter={8}>
          <Col
            lg={8}
            sm={24}
            xs={24}
            style={{ textAlign: "center", marginTop: "2rem" }}
          >
            <LogoFooter />
          </Col>
          <Col lg={8} sm={24} xs={24}>
            <FooterLinksContainer>
              <br />
              <InternalLink url={InternalLinkTypes.Home}>Home</InternalLink>
              <br /> <br />
              <InternalLink url={InternalLinkTypes.Services}>
                Our Services
              </InternalLink>
              <br /> <br />
              <InternalLink url={InternalLinkTypes.Gallery}>
                Our Work
              </InternalLink>
              <br /> <br />
              <InternalLink url={InternalLinkTypes.Quote}>
                Get Quote
              </InternalLink> <br /> <br />
              <InternalLink url={InternalLinkTypes.Reviews}>
                Client Reviews
              </InternalLink>
              <br /> <br />
              <InternalLink url={InternalLinkTypes.Contact}>
                Contact Us
              </InternalLink>
            </FooterLinksContainer>
          </Col>
          <Col lg={8} sm={24} xs={24}>
            <FooterLinksContainer>
              <div style={{ textAlign: "left" }}>
                <ContactInfo />
              </div>
            </FooterLinksContainer>
          </Col>
        </Row>
      </ContainerContentFixed>
      <div>
        <ContainerContentFixed style={{ padding: "0.5rem" }}>
          <div style={{ textAlign: "center" }}>
            <InternalLink url={InternalLinkTypes.PrivacyPolicy}>
              Privacy Policy
            </InternalLink>{" "}
            © {new Date().getFullYear()} Urban Concrete Contractors.
            <span>
              {" "}
              Website built by{" "}
              <ExternalLink
                url={
                  "https://itcians.com.au/referral/?website=Urban+Concrete+Contractors"
                }
              >
                ITcians
              </ExternalLink>
            </span>
          </div>
        </ContainerContentFixed>
      </div>
    </footer>
  )
}

export default Footer
