import React from "react"
import { string } from "prop-types"
import { Colors } from "../constants"

export const BannerPage = ({ title, subTitle }) => (
  <div
    style={{
      backgroundColor: Colors.LightGray,
      padding: "32px",
      textAlign: "center"
    }}
  >
    <h1 style={{ fontSize: "2rem", fontWeight: 200, color: Colors.Blue }}>
      {title}
    </h1>
    {subTitle && <h4 style={{ fontWeight: 400 }}> {subTitle}</h4>}
  </div>
)

BannerPage.propTypes = {
  title: string.isRequired,
  subTitle: string
}
