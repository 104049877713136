import React from "react"
import { object } from "prop-types"
import { Menu } from "antd"
import { InternalLink, InternalLinkTypes } from "./links"
import { Colors } from "./constants"

export const Nav = ({ mode, style }) => (
  <div style={style}>
    <Menu mode={mode} theme={"dark"} style={{ background: Colors.Blue }}>
      <Menu.Item key={InternalLinkTypes.Home}>
        <InternalLink url={InternalLinkTypes.Home}>Home</InternalLink>
      </Menu.Item>
      <Menu.Item key={InternalLinkTypes.Services}>
        <InternalLink url={InternalLinkTypes.Services}>
          Our Services
        </InternalLink>
      </Menu.Item>
      <Menu.Item key={InternalLinkTypes.Gallery}>
        <InternalLink url={InternalLinkTypes.Gallery}>Our Work</InternalLink>
      </Menu.Item>
      <Menu.Item key={InternalLinkTypes.Quote}>
        <InternalLink url={InternalLinkTypes.Quote}>Get Quote</InternalLink>
      </Menu.Item>
      <Menu.Item key={InternalLinkTypes.Reviews}>
        <InternalLink url={InternalLinkTypes.Reviews}>Client Reviews</InternalLink>
      </Menu.Item>
      <Menu.Item key={InternalLinkTypes.Contact}>
        <InternalLink url={InternalLinkTypes.Contact}>Contact Us</InternalLink>
      </Menu.Item>
    </Menu>
  </div>
)

Nav.propTypes = {
  style: object
}
