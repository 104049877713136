import React from "react"

export const TitleSection = ({ children, style }) => (
  <h2
    style={{
      fontSize: "2rem",
      fontWeight: "400",

      ...style
    }}
  >
    {children}
  </h2>
)
