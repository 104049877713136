import React from "react";


import { Row, Col, Card, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import {Colors} from "../constants";


export const DisplayReviewList = ({ reviews }) => {
  return (
    <Row gutter={[32, 32]}>
      {reviews.map(r => (
        <Col key={r.client} md={8} sm={24} xs={24}>

            <Card
              hoverable={true}
            >
              <Card.Meta
                title={[1, 2, 3, 4, 5].map(item => (
                  <FontAwesomeIcon
                  key={`star-${item}`}
                  icon={faStar}
                  style={{
                  fontSize: "24px",
                  marginTop: "4px",
                  marginBottom: "4px",
                  marginRight: "4px",
                  color: "#F9A72B"
                }}
                  />
                  ))}
                description={
                  <div>
                    <div
                        style={{
                          minHeight: "160px",
                          marginTop: "16px",
                          color:"#111"
                          // overflow: "hidden",
                          // textOverflow: "ellipsis"
                        }}
                    >
                      {r.review.map(row => <p>{row}</p>)}
                    </div>{" "}
                    <div style={{ color: Colors.Blue, fontSize: "1.1rem" }}>
                      <span style={{ fontWeight: "600" }}> - {r.client}</span>

                    </div>
                    <div style={{ fontSize: "0.8rem", color: "#aaa" }}>
                      <span>Source: </span>
                      <Space>
                        <DisplayPlatform platformId={r.platform} />
                      </Space>
                    </div>
                  </div>
                }
              />


            </Card>
        </Col>
      ))}
    </Row>
  );
};

const DisplayPlatform = ({ platformId }) => {
  switch (platformId) {
    // case "google":
    //   return (
    //     <ExternalLink url={ExternalLinkTypes.ProductReviewLink}>
    //       Google
    //     </ExternalLink>
    //   );
    default:
      return platformId;
  }
};
