import React from "react"
import { object } from "prop-types"
import logo from "./ucc_white_portrait.png"

export const LogoFooter = ({ style }) => {
  return (
    <img
      alt={"logo_footer"}
      src={logo}
      style={{
        maxWidth: "300px",
        padding: "2rem 0.5rem",
        // marginTop: "-150px",
        // marginLeft: "-100px",
        // marginBottom: "-200px",
        // marginRight: "-200px",
        ...style
      }}
    />
  )
}

LogoFooter.propTypes = {
  style: object
}
