import React from "react"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { ExternalLink, ExternalLinkTypes } from "../links"
import { ContactListItem } from "../contact/contact_list_item"

export const SocialMedia = () => (
  <div style={{ textAlign: "left" }}>
    <ContactListItem
      icon={faFacebook}
      title={"Facebook"}
      content={
        <ExternalLink url={ExternalLinkTypes.FacebookLink}>
          {ExternalLinkTypes.FacebookText}
        </ExternalLink>
      }
    />
    <ContactListItem
      icon={faInstagram}
      title={"Instagram"}
      content={
        <ExternalLink url={ExternalLinkTypes.InstagramLink}>
          {ExternalLinkTypes.InstagramText}
        </ExternalLink>
      }
    />
  </div>
)
